<template>
  <div>
    <profileParts />
    <h2 class="flix-html-h2" v-html="$t('message.dataProcessingContract')" />
    <div class="flix-form-group" v-html="$t('message.dataProcessingContractInfo')" />
    <div class="flix-form-group">
      <editBtn :onClick="function () { setClick() }"><template v-slot:text>{{ $t('message.goTo', { name: $t('message.supportChat')}) }}</template></editBtn>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" :key="open" v-if="open">
        <helpChat />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    profileParts () { return import('@/components/profile/parts') },
    helpChat () { return import('@/components/chat/helpChat') }
  },
  props: {},
  data () {
    return {
      open: false
    }
  },
  methods: {
    setClick () {
      this.open = !this.open
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
